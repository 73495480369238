// simple modal
'use strict';

;(function ($, window, document, undefined) {

	var pluginName = 'modal',
		defaults = {
			effect: 'scale'
		};

	function Plugin(element, options) {
		this.element = element;
		this.$elem = $(this.element);
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = pluginName;
		this.init();
		//this.close();
	}

	Plugin.prototype = {

		init: function init() {
			this.$elem.on('click', { myOptions: this.options }, function (e) {
				e.preventDefault();

				var $options = e.data.myOptions;
				var effect = $options.effect;
				var $this = $(this);

				var $target = $this.data('modal');
				console.log($target)
				var $body = $('html, body');

				// open modal and add overlay
				$('.' + $target).addClass('-is-open').before('<div class="modal-overlay"></div>');
				$('.' + $target).append('<div class="modal-close"></div>');
				$body.css('overflow', 'hidden');

				// close modal
				var $modalOverlay = $body.find('.modal-overlay').hide();
				var $modalClose = $body.find('.modal-close');

				$modalOverlay.fadeIn(150);

				function closeModal() {
					$('.modal').removeClass('-is-open');

					$modalOverlay.fadeOut(150, function() {
						$modalOverlay.remove();
						$modalClose.remove();
					});

					$body.css('overflow', 'auto');
				}

				$modalOverlay.on('click', { myOptions: this.options }, closeModal);
				$modalClose.on('click', { myOptions: this.options }, closeModal);
			});
		}
	};

	$.fn[pluginName] = function (options) {
		return this.each(function () {
			new Plugin(this, options);
		});
	};

	$(function () {
		// init modals
		$('.-has-modal').modal();
	});
})(jQuery, window, document);